<div class="slideshow-container" *ngIf="files && files.length > 0">
  <div class="steps-button">
    <button class="btn btn-md icon" type="button" (click)="onPagination(-1)">
      <i class="fa-solid fa-arrow-left"></i>
    </button>
    <button class="btn btn-md icon" type="button" (click)="onPagination(1)">
      <i class="fa-solid fa-arrow-right"></i>
    </button>
  </div>

  <div [id]="'slide'"></div>
  <div class="slides fade" *ngFor="let item of files; index as i">
    <div class="numbertext">{{getLabelIndexPage(i)}}</div>
    <img [src]="item.url" class="image-slide blur-up lazyload" *ngIf="item.type === 'img'" />
    <div [id]="'slide-'+ i"></div>
  </div>
</div>
<br>
