<!-- Header Start -->
<header class="header-2">
  <div class="top-nav top-header sticky-header sticky-header-3">
    <div class="container-fluid-lg">
      <div class="row">
        <div class="col-12">
          <div class="navbar-top">
            <button class="navbar-toggler d-xl-none d-block p-0 me-3" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#primaryMenu">
            </button>

            <a [routerLink]="['/']" routerLinkActive="router-link-active" class="web-logo nav-logo">
              <img [src]="validateImageUrl ? validateImageUrl : './assets/images/Red-Alert-Transparent-PNG.png'"
                style="max-height: 60px;" class="img-fluid blur-up lazyload" alt="">
            </a>

            <form [formGroup]="oForm">
              <div class="search-full">
                <div class="input-group">
                  <span class="input-group-text">
                    <i data-feather="search" class="font-light"></i>
                  </span>
                  <input type="text" class="form-control search-type" id="searchTermsButton"
                    placeholder="Search here..">
                  <span class="input-group-text close-search">
                    <i data-feather="x" class="font-light"></i>
                  </span>
                </div>
              </div>

              <div class="middle-box">
                <div class="flex-container center-box">
                  <div class="flex-item searchbar-box order-xl-1 d-none d-xl-block">
                    <input type="search" class="form-control" id="searchTerms" formControlName="edtNameProduct"
                      placeholder="{{'header.search_for_product' | translate}}">
                    <button class="btn search-button" type="submit" (click)="onSubmitSearch()">
                      <i class="iconly-Search icli"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div class="rightside-menu">
              <div class="dropdown-dollar">
                <app-language-selector></app-language-selector>
                <app-currency-selector></app-currency-selector>
              </div>

              <div class="option-list">
                <ul class="right-side-menu">
                  <li class="right-side onhover-dropdown xpto">
                    <a class="header-icon search-box search-icon ">
                      <i data-feather="search" class="iconly-Search icli"></i>
                    </a>

                    <div class="onhover-div ">
                      <div class="input-group">
                        <input type="text" class="form-control search-type "
                          placeholder="{{'header.search_for_product' | translate}}">
                      </div>
                    </div>
                  </li>

                  <li class="right-side onhover-dropdown">
                    <div class="header-icon bag-icon">
                      <ng-container *ngIf="aCartProducts?.length">
                        <small class="badge-number">{{aCartProducts?.length}}</small>
                      </ng-container>
                      <i class="iconly-Bag-2 icli"></i>
                    </div>
                    <div class="onhover-div onhover-cart-custom mensage-car">
                      <div *ngIf="aCartProducts?.length">
                        <ul *ngIf="aCartProducts?.length" class="cart-list">
                          <li *ngFor="let item of aCartProducts" class="w-100">
                            <div class="drop-cart">
                              <div class="drop-image">
                                <img [src]="getImageUrl(item.id)" *ngIf="getImageUrl(item.id); else imageUrl"
                                  class="blur-up lazyload image-view-car" [alt]="item.name">
                                <ng-template #imageUrl>
                                  <i class="fa fa-shopping-cart img-fluid blur-up lazyload"
                                    style="color: #eee; font-size: 40px; padding: 0 12px;"></i>
                                </ng-template>
                              </div>
                              <div class="drop-contain">
                                <div>
                                  <h5>{{item.name}}</h5>
                                </div>
                                <h6><span>
                                    {{(user ? item.quantity : item.quantityViewCart)}} x</span>
                                  {{item.value.toLocaleString('pt-AO', { minimumFractionDigits: 2,
                                  maximumFractionDigits:
                                  2 }) }} AOA
                                </h6>
                                <button (click)="onRemove(item)" class="close-button">
                                  <i class="fa-solid fa-xmark"></i>
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>

                        <div class="price-box">
                          <h5>{{'products.total' | translate}}</h5>
                          <h4 class="theme-color fw-bold">
                            {{nTotalValueProducts.toLocaleString('pt-AO', { minimumFractionDigits: 2,
                            maximumFractionDigits: 2 }) }}
                            AOA
                          </h4>
                        </div>

                        <div class="button-group">
                          <a (click)="onViewCart()" class="btn btn-sm cart-button">
                            {{'products.view_cart' | translate}}
                          </a>
                          <a (click)="onLoad()" class="btn btn-sm cart-button theme-bg-color text-white">
                            {{'checkout.title' | translate}}
                          </a>
                        </div>
                      </div>
                      <div *ngIf="!aCartProducts?.length">
                        <h6>
                          {{'checkout.your_shopping_cart_empty' | translate}}
                        </h6>
                      </div>
                    </div>
                  </li>

                  <li class="right-side onhover-dropdown">
                    <div class="delivery-login-box" style="display: flex;">
                      <div class="delivery-icon">
                        <a class="header-icon bag-icon">
                          <img src="{{sFotoPerfil}}" *ngIf="sFotoPerfil" class="rounded img-fluid rounded-circle"
                            style="height: 43px;max-width: max-content;" alt="">
                          <i class="iconly-Profile icli" *ngIf="!sFotoPerfil"></i>
                        </a>
                      </div>
                      <div class="delivery-detail" style="margin-left: 10px; margin-top:12px;">
                        <h5 *ngIf="!user">
                          {{ 'header.enter_register' | translate }}
                        </h5>
                        <h5 style="max-width: 100px;" class="text-truncate" *ngIf="user">
                          {{ 'header.hello' | translate }}, {{ user.name }}
                        </h5>
                      </div>
                    </div>

                    <div class="onhover-div onhover-div-login">
                      <ul *ngIf="!user" class="user-box-name">
                        <li class="product-box-contain">
                          <i></i>
                          <a [routerLink]="['/login']" routerLinkActive="router-link-active">
                            {{ 'header.log_in' | translate }}
                          </a>
                        </li>
                        <li class="product-box-contain">
                          <a [routerLink]="['/sign-up']">
                            {{ 'header.register' | translate }}
                          </a>
                        </li>
                        <li class="product-box-contain">
                          <a [routerLink]="['/forgot-password']" routerLinkActive="router-link-active">
                            {{ 'header.forgot_password' | translate }}</a>
                        </li>
                      </ul>
                      <ul *ngIf="user" class="user-box-name">
                        <li class="product-box-contain">
                          <i></i>
                          <a [routerLink]="['/account/' + 'profile']" routerLinkActive="router-link-active">
                            {{ 'header.profile' | translate }}
                          </a>
                        </li>
                        <li class="product-box-contain">
                          <i></i>
                          <a [routerLink]="['/account/' + 'orders']" routerLinkActive="router-link-active">
                            {{ 'header.orders' | translate }}
                          </a>
                        </li>
                        <li class="product-box-contain">
                          <i></i>
                          <a [routerLink]="['/account/' + 'tokens']" routerLinkActive="router-link-active">
                            {{ 'header.tokens' | translate }}
                          </a>
                        </li>
                        <li class="product-box-contain">
                          <i></i>
                          <a class="cursor-pointer" (click)="logout()">{{ 'header.logout' | translate }}</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid-lg">
    <div class="row">
      <div class="col-12">
        <div class="main-nav">
          <div class="header-nav-left">
            <button class="dropdown-category dropdown-category-2">
              <i class="iconly-Category icli"></i>
              <span>{{'categories.categories' | translate}}</span>
            </button>

            <div class="category-dropdown">
              <ul class="category-list">
                <li class="onhover-category-list" *ngFor="let item of aCategories">
                  <a (click)="onLoadShopCategories(item)" class="category-name cursor-pointer">
                    <img
                      [src]="item.imageUrl || 'assets/images/no-image.svg'"
                      class="img-fluid blur-up lazyload" [alt]="item.name">
                    <h6>{{item.name}}</h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="right-nav">
            <div class="nav-number">
              <!-- <img src="./assets/images/icon/music.png" class="img-fluid blur-up lazyload" alt=""> -->
              <i style="font-size: 20px;" class="color-i fa fa-headphones"></i>
              <span style="font-size: 18px;">{{phoneConactDescription}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- Header End -->
<div appDynamicScript>
  <div src="./assets/js/components/header.component.js"></div>
</div>