import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { FicheiroService } from '../../services/ficheiro.service';

@Component({
  selector: 'app-file-viewer-gallery',
  templateUrl: './file-viewer-gallery.component.html',
  styleUrls: ['./file-viewer-gallery.component.scss']
})
export class FileViewerGalleryComponent implements OnInit {

  @Input() files: Array<any> = new Array<any>();

  _slideIndex: number = 1;
  _settings: string = environment.endpoint.url;

  constructor(
    private oDomSanitizer: DomSanitizer,
    private oFicheiroService: FicheiroService
  ) { }

  ngOnInit(): void {
    if (this.files && this.files.length > 0) {
      window.setTimeout(() => {
        this.getFiles();
        this.getPages(this._slideIndex);
      });
    }
  }

  onPagination(index: number) {
    this.getPages(this._slideIndex += index);
  }

  getLabelIndexPage(index: number) {
    return `${index + 1} / ${this.files.length}`;
  }

  getUrlImage(contentId: string) {
    return this.oDomSanitizer.bypassSecurityTrustResourceUrl(`${this._settings}/alfresco/nodes/${contentId}/content?attachment=false`);
  }

  private getPages(index: number) {
    const slides = document.getElementsByClassName('slides') as any;

    if (index > slides.length) {
      this._slideIndex = 1;
    }
    if (index < 1) {
      this._slideIndex = slides.length;
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slides[this._slideIndex - 1].style.display = "block";
  }

  private async getFiles() {
    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];

      this.oFicheiroService.getContent(file.contentId).subscribe(async (fileItem: { blobFile: Blob, contentId: string, contentType: string, url: string }) => {

        if (fileItem.contentType.includes('image')) {
          file.type = 'img';
          file.url = this.oDomSanitizer.bypassSecurityTrustResourceUrl(fileItem.url);
        } else if (fileItem.contentType.includes('pdf')) {
          file.type = 'pdf';
          file.url = fileItem.url;
          const div = document.getElementById(`slide-${index}`);
          if (div) {
            const frame = document.createElement('iframe');
            frame.setAttribute('style', 'width: 100%; height: 450px;');
            frame.setAttribute('frameBorder', '0');
            frame.src = file.url;
            div.appendChild(frame);
          }
        } else {
          file.type = 'download';
          const extension = fileItem.contentType.split('/').pop();
          const div = document.getElementById(`slide-${index}`);
          if (div) {
            const downloadLink = document.createElement('a');
            downloadLink.setAttribute('href', fileItem.url);
            downloadLink.setAttribute('download', `download.${extension}`);
            downloadLink.setAttribute('style', 'display: inline-block; margin-top: 10px; text-decoration: none; color: #000;');

            const icon = document.createElement('i');
            icon.setAttribute('class', 'fa-solid fa-download');
            icon.setAttribute('style', 'margin-right: 5px;');

            downloadLink.appendChild(icon);
            downloadLink.innerHTML = `<i class="fa-solid fa-download" style="margin-right: 5px;"></i> Download do ficheiro`;
            div.appendChild(downloadLink);
          }
        }
      });
    }
  }

}
