import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { FiscalDocumentAdapter } from '../adapters/doc-fiscal.adapter';
import { OrderSuccessAdapter } from '../adapters/order.adapter';
import { PaymentQrCodeAdapter } from '../adapters/qr-code.adapter';
import { DocFiscal } from '../models/doc-fiscal';
import { Order } from '../models/order';
import { QrCode } from '../models/qr-code';
import { IReview } from '../models/review';
import { Domain } from '../models/domain';
import { DomainAdapter } from '../adapters/domain.adapter';

@Injectable({ providedIn: 'root' })
export class OrderService {

  private readonly CHECKOUT_URL = '/vendasLoja';
  private readonly PESSOA_URL = '/pessoaLoja';
  private readonly UTIL_URL = '/comumLoja/getDominio'

  constructor(
    private oHttp: HttpClient,
    private oUtilsService: UtilsService,
    private oDomainAdapter: DomainAdapter,
    private oOrderSuccessAdapter: OrderSuccessAdapter,
    private oFiscalDocumentAdapter: FiscalDocumentAdapter,
    private oPaymentQrCodeAdapter: PaymentQrCodeAdapter,
  ) { }

  getHistoricSaleByRequest(id: number): Observable<Order> {
    const oParams = {} as any;

    oParams['idContrato'] = id;

    return this.oHttp.get<any>(`${this.CHECKOUT_URL}/getHstPedido`, { params: oParams }).pipe(map(item => {
      if (item.lines && item.lines) {
        return this.oOrderSuccessAdapter.adapter(item.lines);
      }
      return {} as Order;
    }));
  }

  getHistoricProduceByRequest(id: number): Observable<any> {
    const oParams = {} as any;
    oParams['idContrato'] = id;

    return this.oHttp.get<any>(`${this.PESSOA_URL}/getProcedimentoDet`, { params: oParams }).pipe(map(item => {
      return item
    }));
  }


  getHistoricValidateForm(id: number): Observable<any> {
    const oParams = {} as any;
    oParams['idContrato'] = id;

    return this.oHttp.get<any>(`${this.PESSOA_URL}/validaDtFormulario`, { params: oParams }).pipe(map(item => {
      return item
    }));
  }

  getHistoricSessionByRequest(idContrato: number | null, idDadoTpContrato?: number | null, sgTemplate?: string): Observable<any> {
    const oParams = {} as any;

    if (idContrato) {
      oParams['idContrato'] = idContrato;
    }

    if (idDadoTpContrato) {
      oParams['idDadoTpContrato'] = idDadoTpContrato;
    }

    if (sgTemplate) {
      oParams['sgTemplate'] = sgTemplate; /* EXAME_FISICO -  ANAMNESE_CORPORAL */
    }

    return this.oHttp.get<any>(`${this.PESSOA_URL}/lstQuestionario`, { params: oParams }).pipe(map(item => {
      if (item.lines && item.lines) {
        return item.lines;
      }
      return {} as Order;
    }));
  }

  getHistoricSessionFinalByRequest(id: number): Observable<any> {
    const oParams = {} as any;

    oParams['idContratoMonitoramento'] = id;

    return this.oHttp.get<any>(`${this.PESSOA_URL}/getSessoesDet`, { params: oParams }).pipe(map(item => {
      return item
    }));
  }

  salvarResposta(id: number, data?: Array<any>): Observable<any> {
    const oParams = {} as any;
    oParams['idContrato'] = id;
    oParams['respostas'] = JSON.stringify(data);

    return this.oHttp.put(`${this.PESSOA_URL}/salvarResposta`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  getPDFDocFiscal(id: number): Observable<DocFiscal> {
    const oParams = {} as any;

    oParams['idContrato'] = id;

    return this.oHttp.get<any>(`${this.CHECKOUT_URL}/getPDFDocFiscal`, { params: oParams }).pipe(map(data => {
      if (data.length) {
        return this.oFiscalDocumentAdapter.adapter(data[0]);
      }
      return {} as DocFiscal;
    }));
  }

  getPaymentQrCodeByRequest(id: number): Observable<QrCode> {
    const oParams = {} as any;

    oParams['idContrato'] = id;

    return this.oHttp.get<any>(`${this.CHECKOUT_URL}/getQrCode`, { params: oParams }).pipe(map(data => {
      if (data) {
        return this.oPaymentQrCodeAdapter.adapter(data);
      }
      return {} as QrCode;
    }));
  }

  getReviewSession(idContratoMonitoramento: number): Observable<any> {
    const oParams = {} as any;

    oParams['idContratoMonitoramento'] = idContratoMonitoramento;

    return this.oHttp.get<any>(`${this.PESSOA_URL}/getCamposPesquisa`, { params: oParams }).pipe(map(res => {
      return res || of([]);
    }));
  }

  saveReviewSession(review: IReview) {
    const oParams = {} as any;

    oParams['idContratoMonitoramento'] = review.idContratoMonitoramento;
    oParams['tpNivelSatisfacao'] = review.tpNivelSatisfacao;
    oParams['dsJustificativaAvaliacao'] = review.dsJustificativaAvaliacao;

    return this.oHttp.put(`${this.PESSOA_URL}/salvarPesquisa`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  getDomainListByRequest(): Observable<Domain[]> {
    const oParams = {} as any;

    oParams['noDominio'] = 'TP_NIVEL_SATISFACAO';

    return this.oHttp.get<any>(this.UTIL_URL, { headers: this.oUtilsService.requestPublic(true), params: oParams }).pipe(map(item => {
      if (item && item.length) {
        return item.map((data: any) => this.oDomainAdapter.adapter(data));
      }
      return [];
    }));
  }

}
