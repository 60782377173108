import { UserProfileAdapter } from './../../../core/adapters/user.profile.adapter';
import { Endereco } from './../../../shared/model/endereco';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { accountPage, IAccessData } from '../models/account';
import { UserContacts } from 'src/app/core/models/user-contacts';
import { UserContactsAdapter } from 'src/app/core/adapters/user-contacts.adapter';
import { UserProfile } from 'src/app/core/models/user-profile';
import { TokenProcedureAdapter } from 'src/app/shopping/adapters/token-procedure.adapter';
import { TokenProcedure } from 'src/app/shopping/models/procedure';
import { SimNao } from 'src/app/shared/enums/sim-nao';

@Injectable()
export class AccountService {
  private readonly sUrl_vendasLoja = '/vendasLoja';
  private readonly sUrl_pessoaLoja = '/pessoaLoja';
  private readonly sPath = '/perfil';
  private readonly API_USER = '/utilizador';

  loadPedidos: accountPage | null;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private oUserContactsAdapter: UserContactsAdapter,
    private oUserProfileAdapter: UserProfileAdapter,
    private oTokenProcedureAdapter: TokenProcedureAdapter
  ) {
    this.reset();
  }

  reset(): void {
    this.loadPedidos = null;
  }

  detailPedidos() {
    const oParams = {} as any;
    if (this.loadPedidos) {
      if (this.loadPedidos.flLstHistorico) {
        oParams['flLstHistorico'] = this.loadPedidos.flLstHistorico;
      }
      if (this.loadPedidos.flLstProdutos) {
        oParams['flLstProdutos'] = this.loadPedidos.flLstProdutos;
      }
      if (this.loadPedidos.flLstEndParceiro) {
        oParams['flLstEndParceiro'] = this.loadPedidos.flLstEndParceiro;
      }
      if (this.loadPedidos.flLstEndCliente) {
        oParams['flLstEndCliente'] = this.loadPedidos.flLstEndCliente;
      }
      if (this.loadPedidos.flLstLogoParceiro) {
        oParams['flLstLogoParceiro'] = this.loadPedidos.flLstLogoParceiro;
      }
      if (this.loadPedidos.flSistema) {
        oParams['flSistema'] = this.loadPedidos.flSistema;
      }
      if (this.loadPedidos.orderBy) {
        oParams['orderBy'] = this.loadPedidos.orderBy;
      }
      if (this.loadPedidos.idCliente) {
        oParams['idCliente'] = this.loadPedidos.idCliente;
      }
      if (this.loadPedidos.idContrato) {
        oParams['idContrato'] = this.loadPedidos.idContrato;
      }
    }
    return this.http.get(`${this.sUrl_vendasLoja}/lst`, { params: oParams }).pipe(
      map((oRet: any) => {
        return oRet || [];
      }));
  }

  saveEndereco(dataEndereco: Endereco) {
    const oParams = {} as any;

    if (dataEndereco) {
      if (dataEndereco.idPesEndereco) {
        oParams['idPesEndereco'] = dataEndereco.idPesEndereco;
      }
      if (dataEndereco.dsMorada) {
        oParams['dsEndereco'] = dataEndereco.dsMorada;
      }
      if (dataEndereco.dsPtoReferencia) {
        oParams['dsPtoReferencia'] = dataEndereco.dsPtoReferencia;
      }
      if (dataEndereco.flPrincipal) {
        oParams['flPrincipal'] = dataEndereco.flPrincipal;
      }
      if (dataEndereco.flCorrespondencia) {
        oParams['flCorrespondencia'] = dataEndereco.flCorrespondencia;
      }
      if (dataEndereco.idPais) {
        oParams['idPais'] = dataEndereco.idPais;
      }
      if (dataEndereco.idComuna) {
        oParams['idLocalidade'] = dataEndereco.idComuna;
      }
      if (dataEndereco.tpEndereco) {
        oParams['tpEndereco'] = dataEndereco.tpEndereco;
      }
    }

    // Update
    if (dataEndereco.idPesEndereco) {
      return this.http.put(`${this.sUrl_pessoaLoja}/updPessoaEndereco`, oParams).pipe(
        map((oRet: any) => {
          return oRet || of([]);
        }));
    }
    // Insert
    else if (!dataEndereco.idPesEndereco) {
      return this.http.post(`${this.sUrl_pessoaLoja}/insPessoaEndereco`, oParams).pipe(
        map((oRet: any) => {
          return oRet || of([]);
        }));
    }
    return of([])
  }

  deleteEndereco(idPesEndereco: number) {
    const oParam = {} as any;
    oParam['idPesEndereco'] = idPesEndereco;

    return this.http.delete(`${this.sUrl_pessoaLoja}/delPessoaEndereco?idPesEndereco=${idPesEndereco}`);
  }

  getEndereco() {
    const oParam = {} as any;

    return this.http.get(`${this.sUrl_pessoaLoja}/getEndereco`, { params: oParam }).pipe(
      map((oRet: any) => {
        return oRet || [];
      }));
  }

  getLocalidade(idLocalidadePai?: number, idPais?: any): Observable<any[]> {
    let oParams = new HttpParams();
    if (idLocalidadePai)
      oParams = oParams.set('idLocalidadePai', idLocalidadePai);
    else
      oParams = oParams.set('idPais', idPais);

    return this.http.get(`${this.sUrl_pessoaLoja}/lstLocalidade`, { params: oParams }).pipe(
      map((item: any) => {
        if (item.lines && item.lines.length) {
          return item.lines.map((localidade: any) => { return localidade });
        }
        return [];
      }));
  }

  renderImage(contentId: any) {
    return this.utilsService.imageUrlToString(contentId) || null;
  }

  getUserContactsByRequest(): Observable<UserContacts[]> {
    const oParams = {};

    return this.http.get(`${this.sUrl_pessoaLoja}/lstContato`, { params: oParams }).pipe(map((item: any) => {
      if (item && item.lines && item.lines.length) {
        return item.lines.map((contact: any) => this.oUserContactsAdapter.adapter(contact));
      }
      return [];
    }));
  }

  getUserProcedureByRequest(idDadoTpContrato?: number[]): Observable<any[]> {
    const oParams = {} as any;

    if (idDadoTpContrato?.length) {
      oParams['idDadoTpContrato'] = JSON.stringify(idDadoTpContrato);
    }

    return this.http.get(`${this.sUrl_pessoaLoja}/lstProcedimentos`, { params: oParams }).pipe(map((item: any) => {
      return item || of([]);
    }));
  }

  saveContacts(contacts: UserContacts) {
    const oParams = {} as any;

    oParams['dsContato'] = contacts.description;

    if (contacts.id) {
      oParams['idContato'] = contacts.id;

      return this.http.put(`${this.sUrl_pessoaLoja}/updContato`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    } else {
      oParams['tpContato'] = contacts.type.type;

      return this.http.post(`${this.sUrl_pessoaLoja}/insContato`, oParams).pipe(map((res: any) => {
        return res || of([]);
      }));
    }
  }

  getPersonInfoByRequest() {
    const oParams = {};

    return this.http.get(`${this.sUrl_pessoaLoja}/getInfoPessoa`, { params: oParams }).pipe(
      map((item: any) => {
        return this.oUserProfileAdapter.adapter(item) || [];
      }));
  }

  getFotoPerfil() {
    return this.http.get(`${this.sPath}/getFotoPerfil`).pipe(
      map((res: any) => {
        return res || of([]);
      }));
  }

  savePersonInfo(profile: UserProfile) {
    const oParams = {} as any;

    oParams['noPessoa'] = profile.fullName.trim();
    oParams['dtNascimento'] = profile.birthDate;
    oParams['tpSexo'] = profile.gender;

    return this.http.put(`${this.sUrl_pessoaLoja}/atualizaInfoPessoa`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  getFichas(idPesRelacao: number | undefined): Observable<any[]> {
    const oParams = {} as any;

    oParams['idPesRelacao'] = idPesRelacao;
    oParams['flApenasDisponiveis'] = SimNao.NAO;

    return this.http.get(`${this.sUrl_pessoaLoja}/getDadosContrato`, { params: oParams }).pipe(
      map((res: any) => {
        if (res && res.lines && res.lines.length) {
          return res.lines.map((procedure: any) => this.oTokenProcedureAdapter.adapter(procedure));
        }
      }));
  }

  getDadosContrato(): Observable<TokenProcedure[]> {
    const oParams = {} as any;

    oParams['sgTpDado'] = 'TP_PROCEDIMENTO';

    return this.http.get(`${this.sUrl_pessoaLoja}/getDadosContrato`, { params: oParams }).pipe(
      map((res: any) => {
        if (res && res.lines && res.lines.length) {
          return res.lines.map((procedure: any) => this.oTokenProcedureAdapter.adapter(procedure));
        }
      }));
  }

  saveProcedure(idPesRelacao: number | undefined, idDadoTpContrato: number, questinario: Array<any>, idContrato?: number) {
    const oParams = {} as any;

    if (idContrato) {
      oParams['idContrato'] = idContrato;
    }
    oParams['idPesRelacao'] = Number(idPesRelacao);
    oParams['idDadoTpContrato'] = Number(idDadoTpContrato);
    oParams['questionario'] = JSON.stringify(questinario);


    return this.http.post(`${this.sUrl_pessoaLoja}/gerirVendaDeProcedimento`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }

  changePassword(data: IAccessData) {
    const oParams = {} as any;

    oParams['noUtilizador'] = data.noUtilizador;
    oParams['dsSenha'] = data.dsSenha;
    oParams['dsSenhaNova'] = data.dsSenhaNova;

    return this.http.put(`${this.API_USER}/trocaSenha`, oParams).pipe(map((res: any) => {
      return res || of([]);
    }));
  }
}
