import { Injectable } from "@angular/core";
import { SlugfyService } from "src/app/shared/services/slugfy.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { TokenProcedure } from "../models/procedure";

@Injectable({
  providedIn: 'root'
})
export class TokenProcedureAdapter {

  constructor(
    private utilsService: UtilsService,
    private slugfyService: SlugfyService
  ) { }

  adapter(raw: any): TokenProcedure {
    const procedure: TokenProcedure = {
      id: raw['idDado'] || null,
      idDataType: raw['idTpDado'] || null,
      idRelation: raw['idPessoaSiaf'] || null,
      order: raw['nuOrdem'] || 0,
      name: raw['noDadoOriginal'] || '',
      type: raw['sgDado'] || null,
      imageUrl: null,
      description: raw['noDado'] || '',
      idRoot: raw['idRoot'] || null,
      nameLink: this.slugfyService.transform(raw['noDado']) || '',
    };

    // add image
    const anexos = JSON.parse(raw['anexos']) || [];
    if (anexos && anexos.length) {
      procedure.imageUrl = this.utilsService.imageUrl(anexos[0].contentId);
    } else {
      procedure.imageUrl = 'assets/images/no-image.svg';
    }

    return this.validate(procedure);
  }

  private validate(procedure: TokenProcedure): TokenProcedure {
    if (!procedure.id || !procedure.name || !procedure.nameLink) {
      throw new Error('Error creating procedure, all attributes must be filled in.');
    }
    return procedure;
  }

}
