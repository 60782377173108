import { Injectable } from "@angular/core";
import { User } from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class UserAdapter {

  constructor(
  ) { }

  public adapter(raw: any): User {
    const user: User = {
      id: raw['username'] || null,
      name: (raw['pessoa']) ? raw['pessoa']['nome'] : '' || null,
      username: raw['username'] || null,
      email: raw['email'] || null,
      phone: raw['phone'] || null,
      active: raw['activo'] || null,
      idRelation: raw['idPesRelacao'] || null,
      credential: {
        access_token: raw['credential']['access_token'] || null,
        refresh_token: raw['credential']['refresh_token'] || null,
        token_type: raw['credential']['token_type'] || null,
        id_token: raw['credential']['id_token'] || null,
        expires_in: raw['credential']['expires_in'] || null,
        scope: raw['credential']['scope'] || null,
        created_at: raw['credential']['created_at'] || null
      }
    };
    return this.validate(user);
  }

  public validate(user: User): User {
    if (!user.credential || !user.credential.access_token) {
      throw new Error('Error creating user, all required attributes must be filled in.');
    }
    return user;
  }

}
